<template>
  <div id="employeeVacation" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-4 mt-5"
        >AUTORIZACIÓN DE VACACIONES</v-row
      >
      <v-row no-gutters justify="start" class="mt-5">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="start"
        >
          <v-row>
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="traerAutorizaciones()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
      </v-row>
      <v-container fluid class="base text-center">
        <v-row
          no-gutters
          class="datos pa-0"
          style="height: auto !important; width: 100% !important"
        >
          <v-card
            style="width: 100%; background: rgb(199, 195, 199) !important"
          >
            <v-data-table
              :headers="headers"
              :items="autorizaciones"
              :items-per-page="5"
              :search="search"
              class="elevation-2 text--dark"
              loading-text="Cargando datos... Por favor espere"
              :no-results-text="'No se encontraron incidencias'"
              :no-data-text="'No hay datos'"
              :footer-props="{
                'items-per-page-text': 'Autorizaciones por página',
                'items-per-page': [5, 10, 15],
                'items-per-page-all-text': 'Todas',
                'items-per-page-options': [5, 10],
                'loading-text': 'Obteniendo datos... Por favor espere',
                'no-data-text': 'No hay datos...',
                'no-results-text': 'No se encontraron incidencias',
              }"
            >
              <template v-slot:no-data>
                <v-alert :value="true" color="#ffffff" icon="warning">
                  <p style="color: black">No hay datos en sistema ...</p>
                </v-alert>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{ selectedRow: item === selectedItem }"
                  >
                    <td class="text-sm-center">{{ item.id }}</td>
                    <td class="text-sm-center">
                      {{ item.empleadoSolicita.nombre }}
                      {{ item.empleadoSolicita.apellidoPaterno }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaSolicitud.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaInicioVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaFinVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      <span v-if="item.autorizacionJefe">{{
                        item.jefeAutoriza.nombre +
                        " " +
                        item.jefeAutoriza.apellidoPaterno
                      }}</span>
                    </td>

                    <td class="text-sm-center">
                      {{ item.estatus.descripcionEstatus }}
                    </td>
                    <td class="justify-center layout px-0">
                      <v-btn
                        icon
                        color="green"
                        class="mt-1 text-white"
                        v-if="item.estatus.id == 1"
                        @click="
                          bandera = 1;
                          modal = true;
                          idSolicitud = item.id;
                          personal =
                            item.empleadoSolicita.nombre +
                            ' ' +
                            item.empleadoSolicita.apellidoPaterno;
                        "
                      >
                        <v-icon small>fas fa-check-circle</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="red"
                        class="mt-1 text-white"
                        v-if="item.estatus.id == 1"
                        @click="
                          bandera = 2;
                          modal = true;
                          idSolicitud = item.id;
                          personal =
                            item.empleadoSolicita.nombre +
                            ' ' +
                            item.empleadoSolicita.apellidoPaterno;
                        "
                      >
                        <v-icon small>fas fa-ban</v-icon>
                      </v-btn>
                      <span v-if="item.estatus.id == 4" class="text-sm-center">{{item.motivoDenegaSolicitud}}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
    <!--Modal-->
    <v-dialog v-model="modal" width="370">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Autorización de Solicitud</span>
        </v-card-title>
        <v-card-text class="text-center mt-2">
          <!--Comentario-->
          <h3 v-if="bandera == 1">
            ¿Esta seguro de autorizar la solicitud de {{ personal }}?
          </h3>
          <h3 v-if="bandera == 2">
            ¿Esta seguro rechazar la solicitud de {{ personal }}?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              modal = false;
              idSolicitud = 0;
              bandera = 0;
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="autorizacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Notificación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      search: "",
      expiration: false,
      idUsuario: localStorage.empleadoIdGlobal,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      rolUsuario: localStorage.roles,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Nombre",
          align: "center",
          value: "empleadoSolicita.nombre",
        },
        {
          text: "Fecha solicitud",
          align: "center",
          value: "fechaSolicitud",
        },
        {
          text: "Fecha inicio vacaciones",
          align: "center",
          value: "fechaInicioVacaciones",
        },
        {
          text: "Fecha fin vacaciones",
          align: "center",
          value: "fechaFinVacaciones",
        },
        {
          text: "Autorizo",
          align: "center",
          value: "jefeAutoriza.nombre",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus.descripcionEstatus",
        },
        {
          text: "Autorización",
          align: "center",
          value: "autorización",
          sortable: false,
        },
      ],
      autorizaciones: [],
      modal: false,
      bandera: 0,
      idSolicitud: 0,
      personal: "",
      respuesta: "",
      confirmation: false,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    autorizacion() {
      if (this.bandera == 1) {
        this.show = true;
        axios
          .get(Server + "/vacaciones/autorizacion-jefe/" + this.idSolicitud, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            console.log(response);
            this.personal = "";
            this.bandera = 0;
            this.idSolicitud = 0;
            this.modal = false;
            this.show = false;
            this.traerAutorizaciones();
            this.respuesta = "La solicitud fue autorizada exitosamente";
            this.confirmation = true;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            this.personal = "";
            this.bandera = 0;
            this.idSolicitud = 0;
            this.modal = false;
            this.traerAutorizaciones();
            this.respuesta =
              "La solicitud no fue autorizada, favor de volver a intentarlo";
            this.confirmation = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        this.show = true;
        axios
          .get(Server + "/vacaciones/denega-jefe/" + this.idSolicitud, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            console.log(response);
            this.personal = "";
            this.bandera = 0;
            this.idSolicitud = 0;
            this.modal = false;
            this.show = false;
            this.traerAutorizaciones();
            this.respuesta = "La solicitud fue denegada exitosamente";
            this.confirmation = true;
          })
          .catch((e) => {
            console.log(e);
            this.personal = "";
            this.bandera = 0;
            this.idSolicitud = 0;
            this.modal = false;
            this.show = false;
            this.traerAutorizaciones();
            this.respuesta =
              "La solicitud no fue rechazada, favor de volver a intentarlo";
            this.confirmation = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    traerAutorizaciones() {
      this.show = true;
      axios
        .get(
          Server +
            "/vacaciones/autorizaciones/" +
            this.enterprise +
            "/" +
            this.idUsuario +
            "?role=" +
            this.rolUsuario,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.autorizaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.traerAutorizaciones();
  },
};
</script>